import { Inject, Injectable } from '@angular/core';
import { Observable, Observer, of } from 'rxjs';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { timeAvailability } from './static/time-availability';
import { Note, Reason } from './dashboard/store/patients/patients-list/patients.state';
import { CreatePayload } from './dashboard/patients/patients.component';
import { Country } from './shared/models/countries.models';
import { removeEmptyObjectValues } from './shared/helpers/other';
import { MandatoryAttachmentListModel } from './shared/models/mandatory-attachments.models';
import { AideType, HiringStatusesModel } from './shared/models/others.models';

@Injectable({
  providedIn: 'root',
})
export class StaticDataService {
  urlV2: string = `${this.apiUrl}/v2`;

  constructor(
    private http: HttpClient,
    @Inject('API_URL') private apiUrl: string,
  ) {}

  getLanguages(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/language`, { params: <any>{ showCustom: 0 } });
  }

  getStates(): Observable<any> {
    return this.http.get(`${this.apiUrl}/states`);
  }

  getCertifications(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/certificates`);
  }

  getAvailability(): Observable<any> {
    return of(timeAvailability);
  }

  getPositions(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/position`, { params: <any>{ showCustom: 0 } });
  }

  getCityAutocomplete(query: string): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/autocomplete/city`, { params: { city: query } });
  }

  getDepartments(): Observable<any> {
    return this.http.get(`${this.apiUrl}/departments`);
  }

  getAideTypes(): Observable<AideType[]> {
    return this.http.get<AideType[]>(`${this.apiUrl}/v2/employee/aide-types`);
  }

  getEmployeeHiringStatuses(): Observable<HiringStatusesModel[]> {
    return this.http.get<HiringStatusesModel[]>(`${this.apiUrl}/v2/employee/hiring-statuses`);
  }

  getPatientNoteReason(patientId: string): Observable<Reason[]> {
    return this.http.get<Reason[]>(`${this.apiUrl}/patients/${patientId}/notes/reasons`);
  }

  setStatusPatientHHa(patientId: string, status: string): Observable<any> {
    return this.http.post(`${this.apiUrl}/patients/${patientId}/${status}`, {});
  }

  getPatientNotes(patientId: string, filters: any): Observable<any> {
    return new Observable((observer: Observer<any>) => {
      this.http
        .get<Note[]>(`${this.apiUrl}/patients/${patientId}/notes`, {
          params: removeEmptyObjectValues(filters),
          observe: 'response',
        })
        .subscribe({
          next: (success: HttpResponse<any[]>) => {
            observer.next({
              data: success.body,
              total: +success.headers.get('X-Items-Count'),
            });
            observer.complete();
          },
          error: (err: any) => {
            observer.error(err);
          },
        });
    });
  }

  getCountries(): Observable<Country[]> {
    return this.http.get<Country[]>(`${this.urlV2}/countries`);
  }

  createPatientNote(payload: CreatePayload): Observable<Note[]> {
    return this.http.post<Note[]>(`${this.apiUrl}/patients/${payload.id}/notes`, {
      text: payload.text,
      reason: payload.reason ? +payload.reason : null,
    });
  }

  editPatientNote(payload: CreatePayload): Observable<Note[]> {
    return this.http.put<Note[]>(`${this.apiUrl}/patients/${payload.patientId}/notes/${payload.noteId}`, {
      text: payload.text,
      reason: payload.reason ? +payload.reason : null,
    });
  }

  deletePatientNote(payload: { patientId: string; noteId: string }): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/patients/${payload.patientId}/notes/${payload.noteId}`);
  }

  getPayers(): Observable<any> {
    return this.http.get(`${this.apiUrl}/payers`);
  }

  getRules(): Observable<any> {
    return this.http.get(`${this.apiUrl}/v2/feature-flags`);
  }

  getPatientNoteReasonFilter(patientId: string): Observable<Reason[]> {
    return this.http.get<Reason[]>(`${this.apiUrl}/patients/${patientId}/notes/reasons/filter`);
  }

  getMandatoryAttachmentListByEmployee(uid: string): Observable<MandatoryAttachmentListModel> {
    return this.http.get<MandatoryAttachmentListModel>(`${this.apiUrl}/employee/${uid}/mandatory-attachments`);
  }

  deleteMandatoryAttachmentInEmployee(uid: string, type: string): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/employee/${uid}/mandatory-attachments/${type}`);
  }

  getMandatoryAttachmentByEmployee(uid: string, type: string): Observable<{ url: string }> {
    return this.http.get<{ url: string }>(`${this.apiUrl}/employee/${uid}/mandatory-attachments/${type}`);
  }
}
